.ai-chat-container {
    display: flex;
    flex-direction: column;
    height: 85vh;
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    position: relative;
}

.chat-header {
    background: linear-gradient(135deg, var(--color1st, #4a6cf7) 0%, var(--color2nd, #3a5ce5) 100%);
    color: white;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-header h2, .chat-header h5 {
    margin: 0;
    font-weight: 500;
}

.header-actions {
    display: flex;
    gap: 8px;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #f9fafc;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.chat-messages::-webkit-scrollbar {
    width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-track {
    background-color: transparent;
}

.message {
    position: relative;
    max-width: 85%;
    padding: 14px 18px;
    border-radius: 18px;
    line-height: 1.5;
    word-wrap: break-word;
    font-size: 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.3s ease-out;
    transition: all 0.2s;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.message-content {
    white-space: pre-wrap;
}

.message-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    transition: opacity 0.2s;
}

.message:hover .message-actions {
    opacity: 1;
}

.copy-button {
    color: rgba(0, 0, 0, 0.4) !important;
}

.copy-button:hover {
    color: rgba(0, 0, 0, 0.7) !important;
}

.user-message {
    background-color: #e7f3ff;
    color: #333;
    align-self: flex-end;
    border-bottom-right-radius: 4px;
    border-left: none;
}

.ai-message {
    background-color: white;
    color: #333;
    align-self: flex-start;
    border-bottom-left-radius: 4px;
    border-left: 3px solid var(--color1st, #4a6cf7);
}

.typing {
    animation: pulse 1s infinite alternate;
}

@keyframes pulse {
    from { border-left-color: var(--color1st, #4a6cf7); }
    to { border-left-color: var(--color2nd, #3a5ce5); }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: transparent;
    box-shadow: none;
    border: none;
}

.suggestions {
    padding: 16px 24px;
    background-color: #f1f5fd;
    border-top: 1px solid #e1e5f1;
}

.suggestion-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: #555;
}

.suggestion-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.suggestion-chip {
    background-color: white !important;
    border: 1px solid #e1e5f1 !important;
    transition: all 0.2s !important;
    color: #555 !important;
}

.suggestion-chip:hover {
    background-color: #f7f9ff !important;
    border-color: var(--color1st, #4a6cf7) !important;
}

.chat-input-form {
    display: flex;
    padding: 16px 24px;
    border-top: 1px solid #eaeaea;
    background-color: white;
    position: relative;
    align-items: center;
    gap: 12px;
}

.chat-input-form input {
    flex: 1;
    padding: 14px 18px;
    border: 1px solid #e1e5f1;
    border-radius: 24px;
    font-size: 15px;
    outline: none;
    transition: all 0.3s;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.chat-input-form input.focused {
    border-color: var(--color1st, #4a6cf7);
    box-shadow: 0 2px 6px rgba(74, 108, 247, 0.15);
}

.chat-input-form input:hover:not(:focus):not(:disabled) {
    border-color: #cbd5e1;
}

.chat-input-form input:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
}

.send-button {
    background-color: var(--color1st, #4a6cf7) !important;
    color: white !important;
    border-radius: 50%;
    padding: 8px !important;
    transition: all 0.2s;
}

.send-button:hover:not(:disabled) {
    background-color: var(--color2nd, #3a5ce5) !important;
    transform: scale(1.05);
}

.send-button:disabled {
    background-color: #e1e5f1 !important;
    color: #a0aec0 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .ai-chat-container {
        height: 90vh;
        margin: 10px;
        border-radius: 8px;
    }
    
    .message {
        max-width: 90%;
    }
    
    .chat-header {
        padding: 12px 16px;
    }
    
    .chat-messages {
        padding: 16px;
    }
    
    .chat-input-form {
        padding: 12px 16px;
    }
    
    .suggestions {
        padding: 12px 16px;
    }
    
    .suggestion-chips {
        gap: 6px;
    }
}
